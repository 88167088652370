<template>
  <div class="w-full">
    <!-- Поиск и фильтры -->
    <div class="menu flex justify-between items-center mb-4">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Поиск по имени проекта..."
        class="border rounded px-4 py-2 w-full max-w-xs"
      />
      <div class="pagination flex justify-center items-center mt-4 space-x-4">
        <button
          :disabled="currentPage <= 1"
          @click="changePage(currentPage - 1)"
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
        >
          Предыдущая
        </button>
        <span class="text-gray-700">{{ currentPage }} / {{ totalPages }}</span>
        <button
          :disabled="currentPage >= totalPages"
          @click="changePage(currentPage + 1)"
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
        >
          Следующая
        </button>
      </div>
    </div>

    <!-- Projects Display -->
    <div v-if="paginatedProjects.length" class="products w-full">
      <div
        class="product rounded-lg border bg-card text-card-foreground shadow-sm mb-2"
        v-for="(project, index) in paginatedProjects"
        :key="index"
      >
        <div class="py-3 px-4 border-b">
          <h2 class="text-lg font-semibold text-gray-800">
            {{ project?.name || "Проект" }}
          </h2>
        </div>
        <div class="p-4">
          <!-- Шапка для периодов -->
          <div class="grid grid-cols-4 gap-y-2 text-md">
            <div class="font-medium text-gray-500">Период</div>
            <div class="font-medium text-gray-500 text-right">Лиды</div>
            <div class="font-medium text-gray-500 text-right">Встречи</div>
            <div class="font-medium text-gray-500 text-right">Деньги</div>
   
            <!-- Визуализация данных с помощью grid -->
            <div class="text-gray-700">Пред. день</div>
            <div class="text-right text-gray-900">{{ project?.prevDaySpend || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.prevDayPrice || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.prevDayMoney || "0.00" }}</div>

            <div class="text-gray-700">Пред. неделя</div>
            <div class="text-right text-gray-900">{{ project?.prevWeekSpend || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.prevWeekPrice || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.prevWeekMoney || "0.00" }}</div>

            <div class="text-gray-700">Пред. месяц</div>
            <div class="text-right text-gray-900">{{ project?.prevMonthSpend || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.prevMonthPrice || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.prevMonthMoney || "0.00" }}</div>

            <div class="text-gray-700">Тек. неделя</div>
            <div class="text-right text-gray-900">{{ project?.currentWeekSpend || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.currentWeekPrice || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.currentWeekMoney || "0.00" }}</div>

            <div class="text-gray-700">Тек. месяц</div>
            <div class="text-right text-gray-900">{{ project?.currentMonthSpend || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.currentMonthPrice || "0.00" }}</div>
            <div class="text-right text-gray-900">{{ project?.currentMonthMoney || "0.00" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useOpStore } from "../stores/opStore";

const searchQuery = ref("");
const currentPage = ref(1);
const itemsPerPage = ref(15);
const productStore = useOpStore();

// Фильтрация по поисковому запросу
const filteredProjects = computed(() => {
  if (searchQuery.value) {
    return productStore.products.filter(
      (project) =>
        typeof project.name[0] === "string" &&
        project.name[0].toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  } else {
    return productStore.products;
  }
});

// Пагинация
const paginatedProjects = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredProjects.value.slice(start, end);
});

// Общее количество страниц
const totalPages = computed(() => {
  return Math.ceil(filteredProjects.value.length / itemsPerPage.value);
});

// Смена страницы
const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

// Загрузка данных из стора
onMounted(async () => {
  try {
    await productStore.loadProducts();
  } catch (error) {
    console.error("Ошибка при загрузке данных:", error);
  }
});
</script>

<style scoped>
.loading {
  text-align: center;
  padding: 20px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}
.product{
  
}
.grid-cols-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 7px 10px;
}

.grid div{
  width: 90px;
  white-space: nowrap;
}
.text-right {
  text-align: right;
}

.pagination {
  margin-top: 0px;
}
.pagination span {
  white-space: nowrap;
}

.menu {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 12;
  gap: 20px;
  font-size: 13px;
}
@media only screen and (max-width: 900px) {
  .menu{
    position: static !important;
    flex-direction: column;
    align-items: flex-start;
  }
  .grid div{
    width: 70px;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Светлый цвет */
  border-top: 16px solid #3498db; /* Цвет полосы */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; /* Анимация вращения */
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%)
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
