<template>
  <div class="product rounded-lg border bg-card text-card-foreground shadow-sm mb-2">
    <div class="py-3 px-4 border-b">
      <h2 class="text-lg font-semibold text-gray-800">Бюджет</h2>
    </div>
    <div class="p-4">
      <!-- Шапка для периодов -->
      <div class="grid grid-cols-2 gap-y-2 text-md">
        <div class="font-medium text-gray-500">Период</div>
        <div class="font-medium text-gray-500 text-right">Затраты</div>
        <!-- Данные для визуализации -->
        <div class="text-gray-700">Пред. неделя</div>
        <div class="text-right text-gray-900">{{ influenceStore.influence.SpendPrevWeek }}</div>
        <div class="text-gray-700">Пред. месяц</div>
        <div class="text-right text-gray-900">{{ influenceStore.influence.SpendPrevMonth }}</div>
        <div class="text-gray-700">Тек. неделя</div>
        <div class="text-right text-gray-900">{{ influenceStore.influence.SpendCurrentWeek }}</div>
        <div class="text-gray-700">Тек. месяц</div>
        <div class="text-right text-gray-900">{{ influenceStore.influence.SpendCurrentMonth }}</div>
        <div class="text-gray-700">Остаток бюджета</div>
        <div class="text-right text-gray-900">{{ influenceStore.influence.LeftBudget }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useInfluenceStore } from "@/stores/influenceStore";
const influenceStore = useInfluenceStore();


onMounted(async () => {
  try {
    await influenceStore.loadProducts();
  } catch (error) {
    console.error("Ошибка при загрузке данных:", error);
  }
});
</script>

<style scoped>
.product {
  margin-top: 20px;
  max-width: 500px;
}

.loader {
  border: 16px solid #f3f3f3; /* Светлый цвет */
  border-top: 16px solid #3498db; /* Цвет полосы */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; /* Анимация вращения */
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%)
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
