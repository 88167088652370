<template>
  <header>
    <div class="menu">
      <div class="notion">
        <div class="spend-wrapper">
          <div class="spend"></div>
          <div class="spend-text">- спенд</div>
        </div>
        <div class="price-wrapper">
          <div class="price"></div>
          <div class="price-text">- цена фтд</div>
        </div>
      </div>
      <div class="autoplay-control">
        <label>
          <input type="checkbox" v-model="autoplay" @change="toggleAutoplay" />
          <span style="font-size: 15px">Автопрокрутка</span>
        </label>
      </div>
      <!-- Pagination controls for users -->
      <div
        class="profiles-pagination flex justify-center items-center space-x-4"
      >
        <!-- Previous page button -->
        <button
          :disabled="currentPage <= 1"
          @click="changePage(currentPage - 1)"
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
        >
          Предыдущая
        </button>

        <!-- Current page / Total pages -->
        <span class="text-gray-700">{{ currentPage }} / {{ totalPages }}</span>

        <!-- Next page button -->
        <button
          :disabled="currentPage >= totalPages"
          @click="changePage(currentPage + 1)"
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
        >
          Следующая
        </button>
      </div>
    </div>
  </header>
  <div v-show="userStore.users.length" class="user-container">
    <!-- Передаем пропс users в ProjectList.vue -->
    <div class="projects">
      <!-- Dropdown filter for sorting -->

      <!-- Loop over each user and display their projects -->
      <div class="project" v-for="(user, index) in paginatedUsers" :key="index">
        <h2 class="user-name text-lg mb-4">{{ user.name }}</h2>

        <!-- Grid view (таблица) -->
        <div class="table-wrapper w-full overflow-x-auto sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
              <tr class="table-head">
                <th
                  class="interval px-6 py-3 cursor-pointer"
                  :class="getHeaderClass(user, 'name')"
                  @click="sortBy(user, 'name')"
                >
                  Проект
                </th>
                <div class="date">
                  <th
                    scope="col"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap sticky left-0 bg-white"
                    :class="getHeaderClass(user, 'prevDaySpend')"
                    @click="sortBy(user, 'prevDaySpend')"
                  >
                    Пред. день
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap sticky left-0 bg-gray-50"
                    :class="getHeaderClass(user, 'prevWeekSpend')"
                    @click="sortBy(user, 'prevWeekSpend')"
                  >
                    Пред. неделя
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap sticky left-0 bg-white"
                    :class="getHeaderClass(user, 'prevMonthSpend')"
                    @click="sortBy(user, 'prevMonthSpend')"
                  >
                    Пред. месяц
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap sticky left-0 bg-gray-50"
                    :class="getHeaderClass(user, 'currentWeekSpend')"
                    @click="sortBy(user, 'currentWeekSpend')"
                  >
                    Тек. неделя
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap sticky left-0 bg-white"
                    :class="getHeaderClass(user, 'currentMonthSpend')"
                    @click="sortBy(user, 'currentMonthSpend')"
                  >
                    Тек. месяц
                  </th>
                </div>
              </tr>
            </thead>
            <tbody>
              <div :class="['table-body', `user-${index}`]">
                <div class="table-header">
                  <th
                    class="project-name px-6 py-3 cursor-pointer"
                    :key="index"
                    v-for="(project, index) in paginatedProjects(user)"
                  >
                    {{ project.name }}
                  </th>
                </div>
                <!-- Projects -->
                <div class="table-inner">
                  <ProjectItem
                    v-for="(project, index) in paginatedProjects(user)"
                    :key="index"
                    :project="project"
                    :user="user"
                  />
                </div>
              </div>
            </tbody>
          </table>
        </div>

        <!-- Internal Pagination for each user's projects -->
        <div class="pagination flex justify-center items-center space-x-4 pt-4">
          <button
            :disabled="user.currentPage <= 1"
            @click="changeProjectPage(user, user.currentPage - 1, index)"
            class="px-4 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
          >
            <img
              style="transform: rotate(180deg); width: 20px"
              src="../assets/icons8-right-48.png"
              alt=""
            />
          </button>

          <span class="text-gray-700">
            {{ user.currentPage }} / {{ totalProjectPages(user) }}
          </span>

          <button
            :disabled="user.currentPage >= totalProjectPages(user)"
            @click="changeProjectPage(user, user.currentPage + 1, index)"
            class="px-4 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
          >
            <img
              style="width: 20px"
              src="../assets/icons8-right-48.png"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!userStore.users.length"><div class="loader"></div></div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, watch, onBeforeUnmount } from "vue";
import ProjectItem from "./ProjectItem.vue";
import { useUserStore } from "../stores/userStore";
// Пропсы
const props = defineProps({
  users: {
    type: Array,
    default: () => [], // default value is an empty array
  },
});
const userStore = useUserStore();

// Реактивная переменная для фильтра
const selectedFilter = ref("");

// Применение фильтра для сортировки
const applyFilter = () => {
  userStore.users.forEach((user) => {
    user.sortKey = selectedFilter.value;
    user.sortOrder = -1; // Всегда сортировка по убыванию
    sortBy(user, selectedFilter.value);
  });
};

// Сортировка проектов для каждого пользователя
const sortBy = (user, key) => {
  if (user.sortKey === key) {
    user.sortOrder *= -1; // Инвертируем порядок при повторном клике
  } else {
    user.sortKey = key;
    user.sortOrder = -1; // Всегда сортировка по убыванию при изменении фильтра
  }
};

// Пагинированные пользователи (основная пагинация)
const currentPage = ref(1); // Текущая страница
const itemsPerPage = 4; // Количество пользователей на одну страницу

const paginatedUsers = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return userStore.users.slice(start, end);
});

// Пагинация для проектов (внутренняя пагинация)
const projectsPerPage = 7; // Количество проектов на страницу

const paginatedProjects = (user) => {
  const start = (user.currentPage - 1) * projectsPerPage;
  const end = start + projectsPerPage;
  return sortedProjects(user).slice(start, end);
};

// Общие количество страниц для пользователей
const totalPages = computed(() => {
  return Math.ceil(userStore.users.length / itemsPerPage);
});

// Общие количество страниц для проектов у пользователя
const totalProjectPages = (user) => {
  return Math.ceil(user.projects.length / projectsPerPage);
};
const changePage = (page, isManual = true) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;

    // Останавливаем автопрокрутку только при ручном переключении страниц
    if (isManual && autoplay.value) {
      autoplay.value = false;
      stopAutoplay();
    }
  }
};

// Функция для изменения страницы проектов пользователя
const changeProjectPage = (user, page, index, isManual = true) => {
  if (page > 0 && page <= totalProjectPages(user)) {
    const projectBody = document.querySelector(`.user-${index}`);

    if (!projectBody) {
      console.warn(`Element .user-${index} not found`);
      return;
    }

    const direction = page > user.currentPage ? "exit-left" : "exit-right";

    projectBody.classList.remove("enter");
    projectBody.classList.add(direction);

    setTimeout(() => {
      user.currentPage = page;

      projectBody.classList.remove(direction);
      projectBody.classList.add("enter");
    }, 100);

    // Останавливаем автопрокрутку только при ручном переключении страниц
    if (isManual && autoplay.value) {
      autoplay.value = false;
      stopAutoplay();
    }
  }
};

// Сортировка проектов
const sortedProjects = (user) => {
  if (!user.sortKey) return user.projects;
  return [...user.projects].sort((a, b) => {
    const aValue = parseFloat(a[user.sortKey]) || a[user.sortKey];
    const bValue = parseFloat(b[user.sortKey]) || b[user.sortKey];
    return aValue < bValue
      ? -1 * user.sortOrder
      : aValue > bValue
      ? 1 * user.sortOrder
      : 0;
  });
};

// Функция для получения CSS-класса заголовка
const getHeaderClass = (user, key) => {
  return user.sortKey === key ? "bg-blue-100" : "";
};

const autoplay = ref(true); // Управление автопрокруткой для всех пагинаций
let autoplayInterval = null; // Интервал для общей пагинации и проектов

// Функция для запуска автопрокрутки всех пагинаций
const startAutoplay = () => {
  if (autoplayInterval) {
    // Если интервал уже запущен, не запускаем новый
    return;
  }
  autoplayInterval = setInterval(() => {
    let allUsersCompleted = true;

    paginatedUsers.value.forEach((user, index) => {
      if (user.currentPage < totalProjectPages(user)) {
        changeProjectPage(user, user.currentPage + 1, index, false); // Передаем false
        allUsersCompleted = false;
      }
    });

    if (allUsersCompleted) {
      if (currentPage.value < totalPages.value) {
        changePage(currentPage.value + 1, false); // Передаем false
      } else {
        currentPage.value = 1;
      }

      paginatedUsers.value.forEach((user, index) => {
        changeProjectPage(user, 1, index, false); // Передаем false
      });
    }
  }, 10000);
};

// Функция для остановки автопрокрутки
const stopAutoplay = () => {
  if (autoplayInterval) {
    clearInterval(autoplayInterval);
    autoplayInterval = null;
  }
};

// Переключение автопрокрутки по состоянию флажка
const toggleAutoplay = () => {
  if (autoplay.value) {
    startAutoplay();
  } else {
    stopAutoplay();
  }
};

onBeforeUnmount(() => {
  stopAutoplay();
});

// Автоматическое применение фильтра при изменении
watch(autoplay, (newValue) => {
  if (newValue) {
    startAutoplay();
  } else {
    stopAutoplay();
  }
  // Сохраняем значение autoplay в localStorage
  localStorage.setItem("autoplay", newValue);
});

// Применение фильтра при монтировании компонента
watch(autoplay, (newValue) => {
  if (newValue) {
    startAutoplay();
  } else {
    stopAutoplay();
  }
  // Сохраняем значение autoplay в localStorage
  localStorage.setItem("autoplay", newValue);
});
onMounted(async () => {
  await userStore.loadUsers();
  userStore.users.forEach((user) => {
    user.currentPage = 1; // Инициализируем первую страницу
  });
  applyFilter();

  // Считываем значение автопрокрутки из localStorage
  const savedAutoplay = localStorage.getItem("autoplay");
  if (savedAutoplay !== null) {
    autoplay.value = savedAutoplay === "true";
  }

  if (autoplay.value) {
    startAutoplay();
  }
});
</script>

<style scoped>
th {
  cursor: pointer;
}

.bg-blue-100 {
  background-color: #ebf8ff;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled {
  opacity: 0.5;
}

.filter-section {
  display: flex;
  align-items: center;
}

select {
  cursor: pointer;
}

table {
  width: 100%;
  max-width: 860px;
}
.date th {
  width: 120px;
}
.menu {
  position: sticky;
  padding: 10px;
  right: 20px;
  display: flex;
  margin-left: auto;
  width: fit-content;
  gap: 20px;
  align-items: center;
  z-index: 10;
}

.project {
  width: 100%;
  max-width: 920px;
}

.pagination {
  margin-top: 10px;
  position: absolute;
  top: 0px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  top: -5px;
  right: 20px;
  padding-top: 5px;
}

#filter {
  margin: 0 !important;
}

th {
  width: 120px;
}

.projects {
  display: flex;
  column-gap: 40px;
  flex-wrap: wrap;
  row-gap: 10px;
  padding-top: 20px;
  justify-content: center;
}

#filter,
.profiles-pagination button {
  font-size: 13px;
  padding: 5px 20px;
}

.table-wrapper {
  width: fit-content;
  padding-top: 40px;
  border: 1px solid #e5e7eb;
  height: 425px;
  overflow: hidden;
}

.table-header th:nth-child(7),
.table-inner tr:nth-child(7) {
  border-bottom: none;
}

.date {
  display: flex;
}

.table-body {
  display: flex;
  background: #f9fafb;
}

.table-head {
  display: flex;
  border-top: 1px solid #e5e7eb;
}

.table-header th {
  padding: 15px;
}

.table-header th,
.table-head :nth-child(1) {
  width: 120px;
}

.table-head th {
  height: 40px !important;
  padding: 0px 20px !important;
  display: flex;
  align-items: center;
}

th {
  display: flex;
  gap: 10px;
}

.table-inner {
  width: 100%;
}

.table-head th {
  border-bottom: 1px solid #e5e7eb;
}

.table-header th,
.date th {
  height: 57px;
  border-bottom: 1px solid #e5e7eb;
  padding-left: 16px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: flex;
  align-items: center;
}

.project {
  position: relative;
  margin-bottom: 10px;
  width: fit-content;
  overflow: hidden;
}

.user-name {
  position: absolute;
  top: 5px;
  left: 20px;
}

.table-body {
  display: flex;
  background: #f9fafb;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  opacity: 1;
}

.table-body.exit-left {
  opacity: 0;
}

.table-body.exit-right {
  opacity: 0;
}

.table-body.enter {
  opacity: 1;
}
.table-wrapper {
  height: fit-content;
}
@media only screen and (min-width: 900px) {
  .menu {
    position: absolute;
    top: 0px;
  }
}

@media only screen and (max-width: 900px) {
  header {
    flex-direction: column;
  }

  .menu {
    margin-left: 0;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-left: 0px;
  }
}
@media only screen and (max-width: 650px) {
  .project {
    overflow-x: scroll;
    position: relative;
  }

  .pagination span {
    margin-left: 0px !important;
  }

  .pagination button {
    margin: 0 !important;
    padding: 0 !important;
  }

  .pagination {
  }
}

.autoplay-control label {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date th {
  width: 120px;
  box-sizing: border-box;
}
.table-head th {
  width: 120px !important;
  box-sizing: border-box;
}

.table-head :nth-child(1) th {
  width: 120px;
}

.table-header th {
  width: 120px;
  height: 51px !important;
  box-sizing: border-box !important;
}
.table-inner tr {
  height: 51px !important;
  box-sizing: border-box !important;
}

.table-head th {
  width: 110px !important;
}

.table-head .interval {
  width: 120px !important;
}
.date th {
  width: 110px !important;
}

.user-name{
    font-size: 14px !important;
    font-weight: 600;
    top:0;
  }

  .pagination{
    font-size: 12px !important
  }

  .pagination{
    margin-top: 4px
  }
  .pagination img{
    width: 20px !important;
  }
  .table-wrapper{
    padding-top: 25px;
  }
@media only screen and (max-width: 1800px) {
  .table-head th,
  .table-header th,
  .table-head :nth-child(1) {
    width: 100px;
    font-size: 9px;
  }

  .projects {
    row-gap: 5px;
  }
  .table-header th,
  .date th {
    height: 38px;
  }
  tbody {
    font-size: 10px;
  }
  .table-inner tr,
  .table-header th {
    height: 38px !important;
  }
  .table-inner td {
    padding: 8px 20px;
    line-height: 15px;
  }

  .text-sm {
    line-height: 13px !important;
  }

  .date th {
    width: 100px !important;
  }
  .table-head th {
    width: 100px !important;
  }

  .table-head .interval {
    width: 100px !important;
  }
  .interval {
  }
}

.spend-wrapper,
.price-wrapper,
.notion{

  display: flex;
  align-items: center;
}

.notion{
  flex-direction: column;
  align-items: flex-start
}

.price-wrapper,
.spend-wrapper{
  gap:10px;
}

.price{
  display: flex;
   background: rgb(37 99 235);
   width: 10px;
   height: 10px;
}

.spend{
  display: flex;
  width: 10px;
  height: 10px;
  background: rgb(22 163 74 );

}
.notion .spend-wrapper div{
 
}

.price-wrapper div{
  color: rgb(37 99 235);
}
.spend-wrapper div{
  display: flex;
  color:rgb(22 163 74 );
}
.loader {
  border: 16px solid #f3f3f3; /* Светлый цвет */
  border-top: 16px solid #3498db; /* Цвет полосы */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; /* Анимация вращения */
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%)
}

.price-text,
.spend-text{
  height: 20px;
  display: flex;
  align-items: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
